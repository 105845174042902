<template>
	<v-menu
		v-model="datesPopupMenu"
		offset-y
		bottom
		left
		class="date-range-select"
		max-width="860px"
		:nudge-width="860"
		:close-on-content-click="false"
	>
		<template v-slot:activator="{ on, attrs }">
			<div v-bind="attrs" v-on="on" class="filter">
				<FiltersButton :label="periodLabel" :showFilters="datesPopupMenu" />
			</div>
		</template>
		<ul class="filters-button-list">
			<v-card>
				<v-card-text>
					<v-row>
						<v-col cols="5" md="3" class="py-0">
							<v-list dense class="date-list">
								<v-list-item-group
									:mandatory="!isCustomPeriod"
									v-model="periodSelected"
									active-class="active">
									<v-list-item
										v-for="(period, index) in periods"
										:key="index"
										:ripple="false"
										class="date-list-item">
										<span class="date-label">{{period}}</span>
										<v-chip small :color="index === periodSelected ? 'green01' : ''" v-if="index === defaultPeriod && index === periodSelected">default</v-chip>
									</v-list-item>
								</v-list-item-group>
							</v-list>
						</v-col>
						<v-col cols="7" md="9">
							<v-row>
								<v-col>
									<v-date-picker
										no-title
										range
										:value="value"
										:first-day-of-week="1"
										:allowedDates="allowedDatesFrom"
										:min="minDate"
										:max="maxDateFrom"
										@input="onChangeFrom($event)"
										class="custom-date-picker"
									/>
								</v-col>
								<v-col>
									<v-date-picker
										no-title
										range
										:value="value"
										:first-day-of-week="1"
										:allowedDates="allowedDatesTo"
										:min="minDateTo"
										@input="onChangeTo($event)"
										class="custom-date-picker"
									/>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</ul>
	</v-menu>
</template>

<script>
import FiltersButton from './FiltersButton';

const moment = require('moment');

export default {
	name: 'DateRangeSelect',

	components: {
		FiltersButton
	},
	props: {
		value: {
			type: Array,
			default: () => []
		},
		minDate: {
			type: String
		}
	},
	data () {
		const now = new Date();
		return {
			datesPopupMenu: false,
			dateFormatDisplay: 'MM.DD.YY',
			dateFormatInternal: 'YYYY-MM-DD',
			periods: [
				'Today',
				'Yesterday',
				'This week',
				'Last week',
				'This month',
				'Last month',
				'Last 30 days',
				'Last 90 days',
				'Last 12 months',
				'This year',
				...new Date(now.getFullYear() - 1, 11, 31) > new Date(this.minDate) ? ['Last year'] : []
			],
			periodSelected: 4,
			defaultPeriod: 4,
			isCustomPeriod: false
		};
	},
	computed: {
		formattedTimeRange: function () {
			return this.formatTimeRange(this.value);
		},
		maxDateFrom: function () {
			return this.value[1];
		},
		minDateTo: function () {
			return this.value[0];
		},
		periodLabel () {
			return this.isCustomPeriod ? this.formattedTimeRange : this.periods[this.periodSelected];
		}
	},
	methods: {
		formatTimeRange (dates) {
			if (!dates || dates.length !== 2) return '';

			const date1 = moment(dates[0]);
			const date2 = moment(dates[1]);

			return date1.format(this.dateFormatDisplay) + ' - ' + date2.format(this.dateFormatDisplay);
		},
		allowedDatesFrom (date) {
			return date <= this.value[1];
		},
		allowedDatesTo (date) {
			return date >= this.value[0];
		},
		onChangeFrom (dates) {
			const dateFrom = dates[0];
			const dateTo = this.value[1];
			this.$emit('input', [dateFrom, dateTo]);
			this.periodSelected = null;
			this.isCustomPeriod = true;
		},
		onChangeTo (dates) {
			const dateFrom = this.value[0];
			const dateTo = dates[0];
			this.$emit('input', [dateFrom, dateTo]);
			this.periodSelected = null;
			this.isCustomPeriod = true;
		},
		gotoDate (periodIndex) {
			const period = this.getPeriod(periodIndex);
			const formattedPeriod = (period || []).map((date) => moment(date).format(this.dateFormatInternal));

			if (this.value[0] !== formattedPeriod[0] || this.value[1] !== formattedPeriod[1]) {
				this.$emit('input', formattedPeriod);
			}

			this.datesPopupMenu = false;
		},
		getPeriod (periodIndex) {
			const now = new Date();
			const year = now.getFullYear();
			const monthIndex = now.getMonth();
			const day = now.getDate();

			switch (periodIndex) {
			case 0:
				return this.getToday(year, monthIndex, day);
			case 1:
				return this.getYesterday(year, monthIndex, day);
			case 2:
				return this.getThisWeek(year, monthIndex, day);
			case 3:
				return this.getLastWeek(year, monthIndex, day);
			case 4:
				return this.getThisMonth(year, monthIndex);
			case 5:
				return this.getLastMonth(year, monthIndex);
			case 6:
				return this.getPeriodFromNow(-30);
			case 7:
				return this.getPeriodFromNow(-90);
			case 8:
				return this.getPeriodFromNow(-365);
			case 9:
				return this.getThisYear(year, monthIndex, day);
			case 10:
				return this.getLastYear(year);
			}
		},
		getPeriodFromNow (numberOfDays) {
			const today = new Date();
			return [this.fromDate(today, numberOfDays), today];
		},
		getToday (year, monthIndex, day) {
			const today = new Date(year, monthIndex, day);
			return [today, today];
		},
		getYesterday (year, monthIndex, day) {
			const yesterday = this.fromDate(new Date(year, monthIndex, day), -1);
			return [yesterday, yesterday];
		},
		getThisWeek (year, monthIndex, day) {
			const today = new Date(year, monthIndex, day);
			const weekDay = today.getDay();
			const isTodaySunday = weekDay === 0;
			const dayOfThisMonday = isTodaySunday
				? this.fromDate(today, -6)
				: this.fromDate(today, 1 - weekDay);
			return [dayOfThisMonday, this.fromDate(dayOfThisMonday, 6)];
		},
		getLastWeek (year, monthIndex, day) {
			const thisWeek = this.getThisWeek(year, monthIndex, day);
			return thisWeek.map((date) => this.fromDate(date, -7));
		},
		getThisMonth (year, monthIndex) {
			const firstDayOfThisMonth = new Date(year, monthIndex, 1);
			const firstDayOfNextMonth = monthIndex === 11
				? new Date(year + 1, 0, 1)
				: new Date(year, monthIndex + 1, 1);
			return [firstDayOfThisMonth, this.fromDate(firstDayOfNextMonth, -1)];
		},
		getLastMonth (year, monthIndex) {
			const firstDayOfLastMonth = monthIndex === 0
				? new Date(year - 1, 11, 1)
				: new Date(year, monthIndex - 1, 1);
			const firstDayOfThisMonth = new Date(year, monthIndex, 1);
			return [firstDayOfLastMonth, this.fromDate(firstDayOfThisMonth, -1)];
		},
		getThisYear (year, monthIndex, day) {
			return [new Date(year, 0, 1), new Date(year, monthIndex, day)];
		},
		getLastYear (year) {
			return [new Date(year - 1, 0, 1), new Date(year - 1, 11, 31)];
		},
		fromDate (date, numberOfDays) {
			if (numberOfDays > 0) {
				return moment(date).add(numberOfDays, 'days').toDate();
			}
			return moment(date).subtract(-numberOfDays, 'days').toDate();
		}
	},

	mounted () {
		if (!this.value.length) {
			this.gotoDate(this.periodSelected);
		}
	},

	watch: {
		value (value) {
			const [startDate, endDate] = value.map((date) => new Date(date).setHours(0, 0, 0, 0));

			const periodsAsTimestampsRanges = [...new Array(this.periods.length)]
				.map((_, index) => this.getPeriod(index).map((date) => new Date(date).setHours(0, 0, 0, 0)));

			const periodIndex = periodsAsTimestampsRanges.findIndex(([periodStart, periodEnd]) => startDate === periodStart && endDate === periodEnd);

			this.isCustomPeriod = periodIndex === -1;
			this.periodSelected = periodIndex !== -1 ? periodIndex : null;
		},
		periodSelected () {
			if (this.periodSelected !== null) {
				this.gotoDate(this.periodSelected);
				this.isCustomPeriod = false;
			}
		}
	}

};
</script>

<style lang="scss">
@import "@/scss/_colors.scss";
.date-range-select .filters-button-list {
		background-color: white;
}
.custom-date-picker {
	.date-list {
		border-right: 3px solid $green-01;

		.date-list-item {
			min-height: 36px;
			padding: 0;

			&:hover::before {
				opacity: 0;
			}

			.date-label {
				font-weight: bold;
				font-family: "Optimistic Display", "Noto Sans Khmer", sans-serif;
				font-size: 16px;
				padding: 4px;
			}

			&.active {
				background: transparent;

				.date-label {
					background-color: $green-01;
					border-radius: 0.5em;
					margin-right: 5px;
				}

				&::before {
					opacity: 0;
				}
			}
		}
	}

	.v-date-picker-header__value button{
		border: none !important;
		background-color: transparent !important;
	}
	.v-date-picker-table button {
		background-color: $green-01 !important;
		border: 1px solid $green-01 !important;
	}

}
</style>
