<template>
	<v-card class="UVMetrics elevation-0" color="green01" >
		<v-card-title class="header">
			<h3>{{ title }}: {{ totalUsers }}</h3>
			<v-spacer/>
			<DateRangeSelect
				v-if="showSelect"
				v-model="period"
				@input="loadUVMetrics" />
		</v-card-title>
		<v-card-text>
			<v-row>
				<v-col
					v-for="(metric, idx) in metrics" :key="idx"
					:cols="12 / defaultLoadingCard" :class="idx + 1 <= loadingCardSelected ? '' : 'd-none'">
					<v-card color="green02" elevation="0">
						<v-card-title>
							<h4>{{ idx + 1 }}. {{ metric._id }}</h4>
						</v-card-title>
						<v-card-text class="pt-0">
							<strong>{{ metric.count }}</strong>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
			<span v-if="metrics.length > loadingCardSelected" class="more" @click="loadingCardSelected += defaultLoadingCard">
				Show more <v-icon>mdi-chevron-right</v-icon>
			</span>
		</v-card-text>
	</v-card>
</template>

<script>

const api = require('@/utils/api');
const { mapState } = require('vuex');

import DateRangeSelect from '@/components/DateRangeSelect';

export default {
	components: {
		DateRangeSelect
	},
	props: {
		type: {
			type: String,
			required: true
		}
	},

	data () {
		return {
			title: null,
			showSelect: null,
			metrics: [],
			period: [],
			defaultLoadingCard: 6,
			loadingCardSelected: 6
		};
	},

	methods: {

		showMore (e) {
			document.querySelectorAll('ul.metrics > li').forEach((li) => {
				li.classList.remove('d-none');
			});
			e.target.style.display = 'none';
		},

		async loadTotalUniqueUsers () {
			this.loadingCardSelected = this.defaultLoadingCard;
			this.metrics = await api.loadTotalUniqueUsers();
		},

		async loadUVMetrics () {
			this.loadingCardSelected = this.defaultLoadingCard;
			this.metrics = await api.loadUVMetrics({
				start: this.period[0],
				end: this.period[1]
			});
		}

	},

	computed: {
		...mapState({
			globalDateRange: (state) => state.analytics.globalDateRange
		}),
		totalUsers () {
			return this.metrics.reduce((acc, item) => {
				return acc + item.count;
			}, 0);
		}
	},
	watch: {
		globalDateRange (range) {
			this.period = range;
		}
	},
	mounted () {
		if (this.type === 'totalUniqueUsers') {
			this.title = 'Total Unique Users';
			this.showSelect = false;
			this.loadTotalUniqueUsers();
		}

		if (this.type === 'userUsage') {
			this.title = 'Unique User Usage';
			this.showSelect = true;
		}
	}

};
</script>

<style lang="scss">
@import "@/scss/_colors.scss";

	.UVMetrics {
		ul.metrics {
			display: grid;
			grid-template-columns: repeat(6, 1fr);
			grid-column-gap: 1em;
			grid-row-gap: 1em;
			li {
				background-color: rgb(196, 236, 186);
				font-size: 1.2em;
				padding: 1em;
			}
		}
		.more {
			font-weight: 700;
			display: inline-block;
			margin-top: 1em;
			cursor: pointer;
		}
	}

	.geochart {
		border: solid 1px $green-06;
		.google-visualization-tooltip-item-list {
			margin: 0.5em 0 !important;

			.google-visualization-tooltip-item {
				padding: 0em 1em 0em 1em !important;
				margin: 0 !important;

				span {
					line-height: 1.5;
				}

				.geochart-tooltip {
					min-width: 245px;
					p {
						margin-bottom: 0;
					}
				}
			}
		}
	}

	.header h3{
		font-size: 22px;
		font-family: "Optimistic Display", 'Noto Sans Khmer', sans-serif;
		font-weight: 500;
		color: $green-09;
	}
</style>
