<template>
	<v-card class="elevation-0" color="green01">
		<v-card-title class="header">
			<h3>Download weekly usage reports</h3>
			<v-spacer/>
		</v-card-title>
		<v-card-text id="chart-weekly-usage-report">
			<v-data-table
				:footer-props="footerProps"
				:headers="headers"
				:items="chart"
				:items-per-page="7"
			>
				<template v-slot:[`item.report`]="props">
					<v-btn @click="downloadUsageData(props.item)">
						<v-icon dark>mdi-download</v-icon>
					</v-btn>
				</template>
			</v-data-table>
		</v-card-text>
	</v-card>
</template>

<script>
const api = require('@/utils/api');
const xlsx = require('json-as-xlsx');

export default {
	props: {
		selects: {
			type: Array
		},
		footerProps: {
			type: Object
		},
		options: {
			type: Object
		},
		itemsPerPage: {
			type: Number
		}
	},
	data () {
		return {
			chart: [],
			period: null,
			headers: [
				{ text: 'Week', value: 'weekNumber', sortable: false, width: '20%' },
				{ text: 'Date of publication', value: 'publicationDate', sortable: true, width: '20%' },
				{ text: 'Download report', value: 'report', sortable: false, width: '14%' }
			]
		};
	},
	methods: {
		async downloadUsageData (row) {
			const year = row.publicationDate.substring(0, row.publicationDate.indexOf('-'));
			const data = await api.loadWeeklyReport(row.weekNumber, year);
			const settings = {
				fileName: `Weekly-Usage-Week-${row.weekNumber}-${year}`,
				extraLength: 3
			};
			xlsx(data, settings);
		}
	},
	async mounted () {
		this.chart = await api.loadWeeklyUsageData();
	}
};
</script>

<style>

</style>
