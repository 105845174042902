export default {
	computed: {
		typeToLabel () {
			return {
				'M': 'Market',
				'R': 'Respondent type',
				'T': 'Topic',
				'CO': 'Collateral',
				'ME': 'Methodology',
				'S': 'survey'
			};
		}
	}
};
