<template>
	<v-card class="elevation-0" color="green01">
		<v-card-title class="header">
			<h3>{{ title }}</h3>
			<v-spacer/>
			<date-range-select v-model="period"  />
		</v-card-title>
		<v-card-text :id="`chart-added-${id}`">
			<v-data-table
				class="event__table"
				:footer-props="footerProps"
				:headers="headers"
				:items="chart"
				:items-per-page="itemsPerPage"
				:options="options">
				<template v-slot:[`item.selectedFilter`]="{ item }" v-if="event === gaEvent.federatedSearchFilter">
					<span class="type"
						:data-label="typeToLabel[item.selectedFilter.type]"> {{ item.selectedFilter.type }} </span>
					<span>{{ item.selectedFilter.label }}</span>
				</template>
				<template v-slot:[`item.selectedSurvey`]="{ item }" v-if="event === gaEvent.federatedSearchSurvey || event === gaEvent.explorePageSurveys">
					<span :class="{type: true, survey: item.selectedSurvey.isSurvey}"
						:data-label="typeToLabel[item.selectedSurvey.type]"> {{ item.selectedSurvey.type }} </span>
					<span>{{ item.selectedSurvey.label }}</span>
				</template>
				<template v-slot:[`item.appliedFilter`]="{ item}"  v-if="event === gaEvent.explorePageFilters">
					<span class="type"
						:data-label="typeToLabel[item.appliedFilter.type]"> {{ item.appliedFilter.type }} </span>
					<span>{{ item.appliedFilter.label }}</span>
				</template>
				<template v-slot:[`footer.prepend`]>
					<DownloadButton @click="download"/>
					Download report
					<v-spacer />
				</template>
			</v-data-table>
		</v-card-text>
	</v-card>
</template>

<script>
import DateRangeSelect from '@/components/DateRangeSelect';
import GaEvent from '@/constants/ga-event';
import TypeToLabel from '@/mixins/type-to-label';
import DownloadButton from './DownloadButton.vue';

const api = require('@/utils/api');
const { ANALYTIC_TYPES } = require('@/constants/types');
const { mapState, mapActions } = require('vuex');

export default {
	components: { DateRangeSelect, DownloadButton },
	mixins: [TypeToLabel],
	props: {
		event: {
			type: String
		},
		footerProps: {
			type: Object
		},
		headers: {
			type: Array
		},
		options: {
			type: Object
		},
		itemsPerPage: {
			type: Number
		}
	},
	data () {
		const titles = Object.create({});
		titles[GaEvent.federatedSearchFilter] = 'Federated search: filters';
		titles[GaEvent.federatedSearchSurvey] = 'Federated search: surveys';
		titles[GaEvent.explorePageFilters] = 'Explore page: filters';
		titles[GaEvent.explorePageSurveys] = 'Explore page: surveys';
		titles[GaEvent.talkingPoint] = 'Added talking points';
		const ids = Object.create({});
		ids[GaEvent.federatedSearchFilter] = 'federated-search-filters';
		ids[GaEvent.federatedSearchSurvey] = 'federated-search-surveys';
		ids[GaEvent.explorePageFilters] = 'explored-page-filters';
		ids[GaEvent.explorePageSurveys] = 'explored-page-surveys';
		ids[GaEvent.talkingPoint] = 'talking-point';
		return {
			chart: [],
			period: this.defaultPeriod,
			titles: titles,
			ids: ids
		};
	},
	methods: {
		...mapActions('analytics', [
			'downloadAnalytics'
		]),
		async getData () {
			const result = await api.loadGaEventInformations(this.event, this.period);
			this.chart = this.formatData(result);
		},
		download () {
			this.downloadAnalytics({ types: [this.fromGaEventToAnalyticTypes(this.event)], period: this.period });
		},
		fromGaEventToAnalyticTypes (event) {
			switch (event) {
			case GaEvent.federatedSearchFilter: return ANALYTIC_TYPES.FEDERATED_SEARCH_FILTERS;
			case GaEvent.federatedSearchSurvey: return ANALYTIC_TYPES.FEDERATED_SEARCH_SURVEYS;
			case GaEvent.explorePageFilters: return ANALYTIC_TYPES.EPLORE_PAGE_FILTERS;
			case GaEvent.explorePageSurveys: return ANALYTIC_TYPES.EPLORE_PAGE_SURVEYS;
			case GaEvent.talkingPoint: return ANALYTIC_TYPES.ADDED_TALKING_POINTS;
			}
		},
		formatData (datas) {
			switch (this.event) {
			case GaEvent.federatedSearchFilter:
				return datas.map(r => ({
					dimensions: r.dimensionValues[1].value.split('|'),
					metricValue: r.metricValues[0].value
				})).map(r => ({
					users: r.metricValue,
					searchTerm: r.dimensions[0],
					selectedFilter: {
						type: r.dimensions[1],
						label: r.dimensions[2]
					}
				}));
			case GaEvent.federatedSearchSurvey:
				return datas.map(r => ({
					dimensions: r.dimensionValues[1].value.split('|'),
					metricValue: r.metricValues[0].value
				})).map(r => ({
					users: r.metricValue,
					searchTerm: r.dimensions[0],
					selectedSurvey: {
						type: r.dimensions[1],
						label: r.dimensions[2],
						isSurvey: r.dimensions[1] !== 'S'
					}
				}));
			case GaEvent.explorePageFilters:
				return datas.map(r => ({
					dimensions: r.dimensionValues[1].value.split('|'),
					metricValue: r.metricValues[0].value
				})).map(r => ({
					users: r.metricValue,
					appliedFilter: {
						type: r.dimensions[0],
						label: r.dimensions[1]
					}
				}));
			case GaEvent.explorePageSurveys:
				return datas.map(r => ({
					dimensions: r.dimensionValues[1].value.split('|'),
					metricValue: r.metricValues[0].value
				})).map(r => ({
					users: r.metricValue,
					selectedSurvey: {
						type: r.dimensions[0],
						label: r.dimensions[1]
					}
				}));
			case GaEvent.talkingPoint:
				return datas.map(r => ({
					dimensions: r.dimensionValues[1].value.split('|'),
					metricValue: r.metricValues[0].value
				})).map(r => ({
					users: r.metricValue,
					market: r.dimensions[0],
					respondentType: r.dimensions[1],
					survey: r.dimensions[2]
				}));
			}
			return datas;
		}
	},
	computed: {
		...mapState({
			globalDateRange: (state) => state.analytics.globalDateRange
		}),
		title () {
			return this.titles[this.event];
		},
		id () {
			return this.ids[this.event];
		},
		gaEvent () {
			return GaEvent;
		}
	},
	watch: {
		globalDateRange (range) {
			this.period = range;
		},
		async period () {
			await this.getData();
		}
	}

};
</script>

<style lang="scss">
@import "@/scss/_colors.scss";

	.type {
		background-color: $green-09;
		color: #fff;
		text-transform: uppercase;
		text-align: center;
		width: 19px;
		height: 19px;
		line-height: 19px;
		font-size: 15px;
		font-weight: 500;
		font-family: 'Optimistic Display', 'Noto Sans Khmer', sans-serif;
		border-radius: 50%;
		display: inline-block;
		vertical-align: middle;
		margin: -2px 8px 0 0;
		position: relative;
		&:hover {
			&:before {
				position: absolute;
				content: attr(data-label);
				left: 30px;
				bottom: -4px;
				background-color: $green-09;
				padding: 8px;
				color: #fff;
				line-height: 1.2em;
				font-size: 11px;
				z-index: 101;
				white-space: nowrap;
			}
			&:after {
				content: "";
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 8px 8px 8px 0;
				border-color: transparent $green-09 transparent transparent;
				position: absolute;
				bottom: 2px;
				left: 22px;
				z-index: 101;
			}
		}
		&.survey {
			font-size: 8px;
		}
	}
</style>
