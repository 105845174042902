<template>
	<v-card color="green01" class="elevation-0">
		<v-card-title>
			<h3>Total {{ event === 'login' ? 'visits' : 'downloads'}} by department</h3>
			<v-spacer></v-spacer>
			<DateRangeSelect v-model="period" />
		</v-card-title>
		<v-card-text>
			<v-data-table
				:footer-props="footerProps"
				:headers="headers"
				:items="items"
				:items-per-page="itemsPerPage"
				:options="options">
				<template v-slot:[`footer.prepend`]>
					<DownloadButton @click="download"/>
					Download report
					<v-spacer />
				</template>
			</v-data-table>
		</v-card-text>
	</v-card>
</template>

<script>
import DateRangeSelect from '@/components/DateRangeSelect';
import DownloadButton from './DownloadButton.vue';

const api = require('@/utils/api');
const { mapState, mapActions } = require('vuex');
const { ANALYTIC_TYPES } = require('@/constants/types');

export default {
	components: { DateRangeSelect, DownloadButton },
	props: {
		event: {
			type: String,
			enum: ['login', 'download']
		},
		footerProps: {
			type: Object
		},
		headers: {
			type: Array
		},
		options: {
			type: Object
		},
		itemsPerPage: {
			type: Number
		}
	},
	data () {
		return {
			items: [],
			period: []
		};
	},
	computed: {
		...mapState({
			globalDateRange: (state) => state.analytics.globalDateRange
		})
	},
	methods: {
		...mapActions('analytics', [
			'downloadAnalytics'
		]),
		async download () {
			this.downloadAnalytics({ types: [this.fromEventToAnalyticTypes(this.event)], period: this.period });
		},
		fromEventToAnalyticTypes (event) {
			switch (event) {
			case 'login': return ANALYTIC_TYPES.VISIT_BY_DEPARTMENT;
			case 'download': return ANALYTIC_TYPES.DOWNLOAD_BY_DEPARTMENT;
			}
		},
		async getData () {
			const response = await api.loadAnalytics(this.event, this.period);
			this.items = Object.entries(response).map(([key, val], i) => {
				if (key !== 'event') return { department: key, users: val };
			}).filter((item) => !!item).sort((a, b) => b.users - a.users).map((item, i) => ({
				...item,
				rank: i + 1
			}));
		}
	},
	watch: {
		globalDateRange (range) {
			this.period = range;
		},
		async period () {
			await this.getData();
		}
	}
};
</script>

<style scoped lang="scss">
@import "@/scss/_colors.scss";
h3 {
	font-size: 22px;
	font-family: "Optimistic Display", 'Noto Sans Khmer', sans-serif;
	font-weight: 500;
	color: $green-09;
}
</style>
