<template>
	<div class="analytics">

		<AnalyticsHeader />

		<div class="_container">
			<v-row>
				<v-col cols="12">
					<UVMetrics type="totalUniqueUsers"/>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<UVMetrics type="userUsage"/>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<CollateralDownloadsTable
						class="collateral-downloads-table"
						title="Collateral downloads"
						:footer-props="footerProps"
						:headers="collateralDownloadsHeaders"
						:items-per-page="itemsPerPage"
						:options="options" />
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="sm" md="6" sm="12">
					<department-table
						:footer-props="footerProps"
						:headers="eventDepartmentHeaders"
						:items-per-page="itemsPerPage"
						:options="options"
						event="login"/>
				</v-col>
				<v-col cols="sm" md="6" sm="12">
					<department-table
						:footer-props="footerProps"
						:headers="eventDepartmentHeaders"
						:items-per-page="itemsPerPage"
						:options="options"
						event="download"/>
				</v-col>
			</v-row>

			<v-row>
				<v-col cols="sm" md="6" sm="12">
					<time-spent-table
						:footer-props="footerProps"
						:headers="timeSpentPerLocationHeaders"
						:items-per-page="itemsPerPage"
						:options="options"
						event="country"/>
				</v-col>

				<v-col cols="sm" md="6" sm="12">
					<time-spent-table
						:footer-props="footerProps"
						:headers="timeSpentPerPageHeaders"
						:items-per-page="itemsPerPage"
						:options="options"
						event="pagePath"/>
				</v-col>

			</v-row>


			<v-row>
				<v-col cols="sm" md="6" sm="12">
					<event-table
						:footer-props="footerProps"
						:headers="federatedSearchFiltersHeaders"
						:items-per-page="itemsPerPage"
						:options="options"
						:event="gaEvent.federatedSearchFilter"/>
				</v-col>
				<v-col cols="sm" md="6" sm="12">
					<event-table
						:footer-props="footerProps"
						:headers="federatedSearchSurveysHeaders"
						:items-per-page="itemsPerPage"
						:options="options"
						:event="gaEvent.federatedSearchSurvey"/>
				</v-col>
			</v-row>

			<v-row>
				<v-col cols="sm" md="6" sm="12">
					<event-table
						:footer-props="footerProps"
						:headers="explorePageFiltersHeaders"
						:items-per-page="itemsPerPage"
						:options="options"
						:event="gaEvent.explorePageFilters"/>
				</v-col>
				<v-col cols="sm" md="6" sm="12">
					<event-table
						:footer-props="footerProps"
						:headers="explorePageSurveysHeaders"
						:items-per-page="itemsPerPage"
						:options="options"
						:event="gaEvent.explorePageSurveys"/>
				</v-col>
			</v-row>

			<v-row>
				<v-col cols="12">
					<event-table
						:footer-props="footerProps"
						:headers="talkingPointsHeaders"
						:items-per-page="itemsPerPage"
						:options="options"
						:event="gaEvent.talkingPoint"/>
				</v-col>
			</v-row>

			<v-row>
				<v-col cols="sm" md="6" sm="12">
					<homepage-metrics />
				</v-col>
				<v-col cols="sm" md="6" sm="12">
					<download-weekly-usage-report
						:footer-props="footerProps"
						:items-per-page="itemsPerPage"
						:options="options"/>
				</v-col>
			</v-row>
		</div>

		<LoadingSnackbar :isLoading="isExporting" text="Processing the generation of the export." />

	</div>
</template>

<script>
const api = require('@/utils/api');
import TypeToLabel from '@/mixins/type-to-label';
import GaEvent from '@/constants/ga-event';
import UVMetrics from '@/components/UVMetrics';
import HomepageMetrics from '@/components/HomepageMetrics';
import TimeSpentTable from '@/components/TimeSpentTable';
import EventTable from '@/components/EventTable';
import DownloadWeeklyUsageReport from '@/components/DownloadWeeklyUsageReport';
import CollateralDownloadsTable from '@/components/CollateralDownloadsTable';
import DepartmentTable from '@/components/DepartmentTable.vue';
import AnalyticsHeader from '../components/AnalyticsHeader.vue';
import LoadingSnackbar from '../components/LoadingSnackbar.vue';

const { mapState } = require('vuex');

export default {

	components: {
		UVMetrics,
		HomepageMetrics,
		TimeSpentTable,
		EventTable,
		DownloadWeeklyUsageReport,
		CollateralDownloadsTable,
		DepartmentTable,
		AnalyticsHeader,
		LoadingSnackbar
	},

	mixins: [TypeToLabel],

	data () {
		return {
			itemsPerPage: 7,
			footerProps: {
				'items-per-page-options': [],
				'items-per-page-text': null,
				'disable-items-per-page': true
			},
			options: {
				itemsPerPage: 7
			},
			eventDepartmentHeaders: [
				{ text: 'Rank', value: 'rank', width: '10%' },
				{ text: 'Department', value: 'department', width: '70%' },
				{ text: 'Users', value: 'users', width: '20%' }
			],
			talkingPointsHeaders: [
				{ text: 'Users', value: 'users', width: '10%' },
				{ text: 'Market', value: 'market', width: '30%' },
				{ text: 'Respondent Type', value: 'respondentType', width: '30%' },
				{ text: 'Survey', value: 'survey', width: '30%' }
			],
			federatedSearchFiltersHeaders: [
				{ text: 'Search Term', value: 'searchTerm', width: '30%' },
				{ text: 'Users', value: 'users', width: '10%' },
				{ text: 'Selected filter', value: 'selectedFilter', width: '60%' }
			],
			federatedSearchSurveysHeaders: [
				{ text: 'Search Term', value: 'searchTerm', width: '30%' },
				{ text: 'Users', value: 'users', width: '10%' },
				{ text: 'Selected survey', value: 'selectedSurvey', width: '60%' }
			],
			explorePageFiltersHeaders: [
				{ text: 'Users', value: 'users', width: '20%' },
				{ text: 'Applied filter', value: 'appliedFilter', width: '80%' }
			],
			explorePageSurveysHeaders: [
				{ text: 'Users', value: 'users', width: '20%' },
				{ text: 'Selected survey', value: 'selectedSurvey', width: '80%' }
			],
			timeSpentPerLocationHeaders: [
				{ text: 'Rank', value: 'rank', width: '10%' },
				{ text: 'Country', value: 'label', width: '50%' },
				{ text: 'Users', value: 'users', width: '10%' },
				{ text: 'Average engagement duration', value: 'userEngagementDuration', width: '30%' }
			],
			timeSpentPerPageHeaders: [
				{ text: 'Rank', value: 'rank', width: '10%' },
				{ text: 'Page', value: 'label', width: '50%' },
				{ text: 'Users', value: 'users', width: '10%' },
				{ text: 'Average engagement duration', value: 'userEngagementDuration', width: '30%' }
			],
			collateralDownloadsHeaders: [
				{ text: 'Country(s)', value: 'countryName', width: '10%' },
				{ text: 'Total', value: 'total', width: '10%' },
				{ text: 'English', value: 'counts.report.en', width: '10%' },
				{ text: 'English Supp.', value: 'counts.report.enSupport', width: '10%' },
				{ text: 'Translated', value: 'counts.report.translated', width: '10%' },
				{ text: 'Trans Supp.', value: 'counts.report.translatedSupport', width: '10%' },
				{ text: 'Mini Rep. (EN)', value: 'counts.collateral.miniReportEn', width: '10%' },
				{ text: 'Mini Rep. (Trans)', value: 'counts.collateral.miniReportTranslated', width: '10%' },
				{ text: 'English', value: 'counts.collateral.en', width: '10%' },
				{ text: 'Translated', value: 'counts.collateral.translated', width: '10%' }
			]
		};
	},
	computed: {
		...mapState({
			isExporting: (state) => state.analytics.isExporting
		}),
		gaEvent () {
			return GaEvent;
		}
	},
	async created () {
		await this.checkAdmin();
	},
	methods: {
		async checkAdmin () {
			const user = await api.userInfo();
			if (!user?.admin) this.$router.push('/');
		}
	}
};

</script>

<style lang="scss" scoped>

@import "@/scss/_colors.scss";

  .analytics{
    overflow: hidden;
  }

	.v-data-table {
		background-color: $green-01 !important;
		color: $green-06;
		font-size: 14px;
		flex-grow: 1;
		th {
			color: $green-06 !important;
			white-space: nowrap;
		}
		tbody > tr {
			cursor: pointer;
			color: $green-09;
		}
		.v-data-footer {
			justify-content: flex-end;
			color: $green-06 !important;
		}
	}

	.header h3, .header h2 {
		font-size: 22px;
		font-family: "Optimistic Display", 'Noto Sans Khmer', sans-serif;
		font-weight: 500;
		color: $green-09;
	}
	.header h2 {
		font-size: 16px;
	}

	.collateral-downloads-table {
		.v-card__title.header {
			align-items: flex-start;
		}
		.v-data-table__wrapper > table > thead > tr > th {
			height: auto;
		}
		.v-data-table-header th {
			padding-bottom: 10px !important;
		}
	}

</style>
