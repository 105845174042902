<template>
	<span
		class="download__button"
		@click="$emit('click')">
		<v-icon>mdi-download</v-icon>
	</span>
</template>

<script>

export default {
	name: 'DownloadButton',

	props: {
		event: {
			type: String
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@/scss/_colors.scss";

.download__button {
	cursor: pointer;
	.v-icon {
		color: $green-06;
		background-color: $green-02;
		border-radius: 4px;
		padding: 0 10px;
		margin-right: 10px;
	}
}

</style>
