<template>
	<div>
		<header class="justify-space-between d-flex align-center">
			<h3> Site Analytics </h3>

			<router-link :to="'/'">
				<v-icon color="#003F2E">mdi-close</v-icon>
			</router-link>
		</header>

		<header class="justify-space-between d-flex align-center mb-8">
			<h3> Global Filter </h3>

			<div class="d-flex">

				<DateRangeSelect
					:value="globalDateRange"
					@input="setGlobalDateRange" />

				<div class="ml-8">
					<DownloadButton @click="download"/>
					Download report
				</div>
			</div>
		</header>
	</div>
</template>

<script>
import DateRangeSelect from './DateRangeSelect.vue';
import DownloadButton from './DownloadButton.vue';
const { mapMutations, mapActions, mapState } = require('vuex');

export default {

	name: 'AnalyticsHeader',

	components: { DateRangeSelect, DownloadButton },

	computed: {
		...mapState({
			globalDateRange: (state) => state.analytics.globalDateRange
		})
	},

	methods: {
		...mapMutations('analytics', [
			'setGlobalDateRange'
		]),
		...mapActions('analytics', [
			'downloadAnalytics'
		]),
		download: function () {
			this.downloadAnalytics({ period: this.globalDateRange });
		}
	}
};

</script>

<style lang="scss" scoped>

@import "@/scss/_colors.scss";

header {
  &:first-child {
		margin-top: 73px;
  }

  background-color: $green-01;
  color: $green-09;
  padding: 16px 5%;
  border-bottom: 1px solid #CCD2D8;

  > h3 {
    font-size: 22px;
    font-weight: 500;
		color: $green-09;
    font-family: "Optimistic Display", 'Noto Sans Khmer', sans-serif;
  }

}

</style>
