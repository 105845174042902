<template>
	<v-card class="elevation-0" color="green01">
		<v-card-title class="header">
			<div>
				<h3>{{ title }}</h3>
				<h2>Selected study : {{ study && study.title }}</h2>
			</div>
			<v-spacer/>
			<div class="d-flex">
				<div class="mr-4">
					<FiltersButtonList
						:items="studies"
						v-model="study"
						text="title"
						label="Study">
					</FiltersButtonList>
				</div>
				<div class="mr-4">
					<FiltersButtonList
						:items="markets"
						v-model="selectedMarkets"
						text="label"
						label="Markets"
						multiple>
					</FiltersButtonList>
				</div>
				<div>
					<DateRangeSelect
						minDate="2022-04-18"
						v-model="period"/>
				</div>
			</div>
		</v-card-title>
		<v-card-text>
			<v-data-table
				:footer-props="footerProps"
				:headers="headers"
				:items="items"
				:items-per-page="itemsPerPage"
				:options="options">
				<template #header="{ props: { } }">
					<thead class="v-data-table-top-header">
						<tr>
							<th scope="col"></th>
							<th scope="col"></th>
							<th scope="col" class="separator"><strong>Report</strong></th>
							<th scope="col"></th>
							<th scope="col"></th>
							<th scope="col"></th>
							<th scope="col" class="separator"><strong>Collateral</strong></th>
							<th scope="col"></th>
							<th scope="col"></th>
							<th scope="col"></th>
						</tr>
					</thead>
				</template>
				<template v-slot:[`item.countryName`]="{ item }">
					{{ item.countryName }}
				</template>
				<template v-slot:[`item.total`]="{ item }">
					<strong>{{ item.total }}</strong>
				</template>
				<template v-slot:[`footer.prepend`]>
					<DownloadButton @click="download"/>
					Download report
					<v-spacer />
				</template>
			</v-data-table>
		</v-card-text>
	</v-card>
</template>

<script>

import FiltersButtonList from '@/components/FiltersButtonList';
import DateRangeSelect from '@/components/DateRangeSelect';
import DownloadButton from './DownloadButton.vue';

const api = require('@/utils/api');
const { mapState, mapActions, mapMutations } = require('vuex');
const { ANALYTIC_TYPES } = require('@/constants/types');

export default {
	components: {
		FiltersButtonList,
		DateRangeSelect,
		DownloadButton
	},

	props: {
		title: {
			type: String
		},
		footerProps: {
			type: Object
		},
		headers: {
			type: Array
		},
		options: {
			type: Object
		},
		itemsPerPage: {
			type: Number
		}
	},

	computed: {
		...mapState({
			globalDateRange: (state) => state.analytics.globalDateRange
		}),
		selectedMarketIds () {
			return this.selectedMarkets.map((market) => market.id);
		}
	},

	data () {
		return {
			items: [],
			period: [],
			studies: [],
			markets: [],
			marketsPerSurvey: [],
			study: null,
			selectedMarkets: null
		};
	},

	methods: {
		...mapActions('analytics', [
			'downloadAnalytics'
		]),
		...mapMutations('analytics', [
			'setCollateralDownloadSurveyId'
		]),
		async getData () {
			if (this.study) {
				this.items = await api.loadCollateralAnalyticsInformations(
					this.period[0],
					this.period[1],
					this.study.id,
					this.selectedMarketIds
				);
			}
		},
		download () {
			this.downloadAnalytics({ types: [ANALYTIC_TYPES.COLLATERAL_DOWNLOAD], period: this.period, surveyID: this.study.id });
		}
	},

	watch: {
		study (newStudy) {
			if (!this.study || !this.period) return;

			this.setCollateralDownloadSurveyId(newStudy.id);

			const marketPerSurvey = this.marketsPerSurvey.find((item) => item.surveyId === newStudy.id);

			this.markets = marketPerSurvey?.markets || [];

			this.getData();
		},
		selectedMarkets () {
			this.getData();
		},
		globalDateRange (range) {
			this.period = range;
		},
		period () {
			this.getData();
		}
	},

	async mounted () {
		const { surveys, markets } = await api.loadCollateralDownloadsSelects();
		this.studies = surveys;
		this.marketsPerSurvey = markets;
	}

};
</script>

<style lang="scss">

@import "@/scss/_colors.scss";

.v-data-table__wrapper {
	position: relative;
	thead.v-data-table-top-header {
		> tr:last-child > th {
			border-bottom: none !important;
			height: auto !important;
		}
		th.separator {
			&:before {
				content: "";
				position: absolute;
				margin-left: -10px;
				height: 100%;
				border-left: 1px dashed $light-gray-04;
			}
		}
	}
}

</style>
