<template>
	<v-card class="elevation-0" color="green01">
		<v-card-title class="header">
			<h3>Time spent per {{ title }}</h3>
			<v-spacer/>
			<date-range-select v-model="period" />
		</v-card-title>
		<v-card-text :id="`chart-added-time-spent-per-${title}`">
			<v-data-table
				:footer-props="footerProps"
				:headers="headers"
				:items="chart"
				:items-per-page="itemsPerPage"
				:options="options">
				<template v-slot:[`item.userEngagementDuration`]="{ item }">
					{{ formatTime(item.userEngagementDuration) }} | <span :class="{neg: item.ratio < 0}">{{ item.ratio > 0 ? '+' : '' }}{{ item.ratio }}%</span>
				</template>
				<template v-slot:[`footer.prepend`]>
					<DownloadButton @click="download"/>
					Download report
					<v-spacer />
				</template>
			</v-data-table>
		</v-card-text>
	</v-card>
</template>

<script>
import DateRangeSelect from '@/components/DateRangeSelect';
import DownloadButton from './DownloadButton.vue';

const api = require('@/utils/api');
const { mapState, mapActions } = require('vuex');
const { ANALYTIC_TYPES } = require('@/constants/types');

export default {
	components: { DateRangeSelect, DownloadButton },
	props: {
		event: {
			type: String,
			enum: ['country', 'pagePath']
		},
		footerProps: {
			type: Object
		},
		headers: {
			type: Array
		},
		options: {
			type: Object
		},
		itemsPerPage: {
			type: Number
		}
	},
	data () {
		return {
			chart: [],
			period: [],
			titles: {
				country: 'country',
				pagePath: 'page'
			}
		};
	},
	methods: {
		...mapActions('analytics', [
			'downloadAnalytics'
		]),
		async download () {
			this.downloadAnalytics({ types: [this.fromEventToAnalyticTypes(this.event)], period: this.period });
		},
		fromEventToAnalyticTypes (event) {
			switch (event) {
			case 'country': return ANALYTIC_TYPES.TIME_SPENT_PER_COUNTRY;
			case 'pagePath': return ANALYTIC_TYPES.TIME_SPENT_PER_PAGE;
			}
		},
		formatTime (time) {
			const date = new Date(time * 1000);
			const hours = date.getUTCHours().toString().padStart(2, '0');
			const minutes = date.getUTCMinutes().toString().padStart(2, '0');
			const seconds = date.getSeconds().toString().padStart(2, '0');
			return `${hours}:${minutes}:${seconds}`;
		},

		async getData () {
			this.chart = await api.loadGaTimeReport(this.event, this.period);
		}
	},
	computed: {
		...mapState({
			globalDateRange: (state) => state.analytics.globalDateRange
		}),
		title () {
			return this.titles[this.event];
		}
	},
	watch: {
		globalDateRange (range) {
			this.period = range;
		},
		async period () {
			await this.getData();
		}
	}
};
</script>

<style>
	.neg {
		color: red;
	}
</style>
