<template>
	<v-card class="elevation-0" color="green01">
		<v-card-title class="header">
			<h3>Homepage Metrics</h3>
			<v-spacer/>
			<FiltersButtonList
				:key="homepageMetricsCountries.length"
				:items="homepageMetricsCountries"
				v-model="homepageMetricsCountry"
			/>
			<v-spacer/>
			<DateRangeSelect v-model="homepageMetricsPeriod" />
		</v-card-title>

		<v-card-text id="chart-hp-metrics">
			<div class="hp-metrics-container">
				<img class="hp-metrics-image" src="@/assets/home/hp-metrics.png" alt="hp-metrics">
				<div class="overlay-hp-metrics-image">
				</div>
				<div class="hp-click-metrics sample-experience-clicks">{{homepageMetricsData['Favorite Survey'] || homepageMetricsData['Sample Experience'] || 0}} clicks</div>
				<div class="hp-click-metrics new-study-released1-clicks">{{homepageMetricsData['New Study Released1'] || 0}} clicks</div>
				<div class="hp-click-metrics new-study-released2-clicks">{{homepageMetricsData['New Study Released2'] || 0}} clicks</div>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>

const api = require('@/utils/api');
const { mapState } = require('vuex');

import FiltersButtonList from '@/components/FiltersButtonList';
import DateRangeSelect from '@/components/DateRangeSelect';

export default {
	components: {
		FiltersButtonList,
		DateRangeSelect
	},

	data () {
		return {
			homepageMetricsData: [],
			homepageMetricsCountries: [],
			homepageMetricsPeriod: [],
			homepageMetricsCountry: null
		};
	},
	methods: {
		async getHomepageMetricsData () {
			const result = await api.loadHPMetrics(this.homepageMetricsCountry?.value, this.homepageMetricsPeriod);
			this.homepageMetricsData = result.reduce((prev, curr) => {
				prev[curr.dimensionValues[2].value] = curr.metricValues[0].value;
				return prev;
			}, {});
		},
		async setHomepageMetricsCountries () {
			this.homepageMetricsCountries = await api.loadHPMetricsCountries();
			this.homepageMetricsCountries = this.homepageMetricsCountries.sort((a, b) => a.value.localeCompare(b.value));
		}
	},
	async mounted () {
		await this.setHomepageMetricsCountries();
	},
	computed: {
		...mapState({
			globalDateRange: (state) => state.analytics.globalDateRange
		})
	},
	watch: {
		globalDateRange (range) {
			this.homepageMetricsPeriod = range;
		},
		async homepageMetricsPeriod () {
			await this.getHomepageMetricsData();
		},
		async homepageMetricsCountry () {
			if (!this.homepageMetricsCountry) return;
			await this.getHomepageMetricsData();
		}
	}
};
</script>

<style lang="scss">

@import "@/scss/_colors.scss";

/* Container holding the homepage image and the texts */
.hp-metrics-container {
    position: relative;
	height: 100%;
	width: 100%;
	text-align: center;
}

.hp-metrics-image {
	width: 100%;
}

.overlay-hp-metrics-image {
    background-color: rgba(234, 240, 232, 0.623);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.hp-click-metrics {
	position: absolute;
	color: $green-09;
	font-weight: bolder;
	font-size: 1.1em;
}

.new-study-released2-clicks {
	bottom: 30%;
	left: 44%;
}

.sample-experience-clicks {
	top: 27%;
	left: 44%;
}

.new-study-released1-clicks {
	top: 27%;
	right: 18%;
}

</style>
